export const environment = {
  production: true,
  apiUrl: 'https://api.izyget.com',
  identityApiUrl: 'https://identityapi.dtasistan.com',
  recaptchaKey: '6LcRLsIpAAAAALS4iOMcOGenapq5gru3zmsL50v7',
  fileApiUrl: 'https://api.izyget.com/api/file/global',
  socketEnabled: false,
  priceEnabledWithoutLogin: true,
  webSite: 'https://www.izyget.com'
};
